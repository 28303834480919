import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import CustomProduct from './CustomProduct';
import { AnimationWrap } from 'libs/animations';
import { GridWrapper } from '../ProductWrapper';
import TabSwitch from 'components/forms/TabSwitch';

const products = [
	{
		title: 'Gratis',
		subTitle: 'For opp til 3 anlegg og 2 brukere',
		monthlyPrice: 0,
		monthlyPriceDiscount: 0,
		features: [
			'3 anlegg',
			'2 brukere',
			'Fakturaoversikt to måneder tilbake',
			'Forbruksoversikt to måneder tilbake',
		],
		button: {
			text: 'Velg gratis',
			href: '',
		},
		settings: [],
	},
	{
		title: 'Standard',
		subTitle: 'For opp til 3 anlegg og 2 brukere',
		monthlyPrice: 129,
		monthlyPriceDiscount: 116,
		features: [
			'100 anlegg',
			'5 brukere',
			'Egendefinert prisvarsling',
			'Detaljert kostnad og forbruksoversikt',
			'Ukentlig rapportering',
			'Anleggsadministrasjon',
		],
		button: {
			text: 'Velg standard',
			href: '',
		},
		settings: ['Fremhevet produkt'],
	},
	{
		title: 'Pro',
		subTitle: 'Ubegrenset anlegg og brukere',
		monthlyPrice: 199,
		monthlyPriceDiscount: 179,
		features: [
			'Ubegrenset anlegg',
			'Ubegrenset brukere',
			'Sanntidsmåling',
			'Utjevning av effekttopper',
			'Avansert tilgangsstyring og kontroll',
		],
		button: {
			text: 'Velg pro',
			href: '',
		},
		settings: [],
	},
];

const SwitchWrapper = styled(AnimationWrap)`
	margin-bottom: 20px;
	margin-top: -20px;
	${p =>
		p.theme.media.large(css`
			position: absolute;
			top: -130px;
			right: 0;
			margin-top: 0;
			margin-bottom: 0;
		`)}
`;

/**
 * A component for displaying and comparing product prices.
 *
 * This component renders a list of products along with a switch to toggle between
 * displaying prices with or without discounts. The products are displayed in a grid layout.
 *
 * @component
 * @param {Object} props - The props object for the CustomProducts component.
 * @returns {JSX.Element|null} The rendered product price comparison component JSX element or null if no products are provided.
 */
export default function CustomProducts({ ...props }) {
	const [activeTab, setActiveTab] = useState('yearly');

	// Check if any of the products are highlighted
	const anyhighlighted =
		products?.length > 0 &&
		products?.some(p => p?.settings?.includes('Fremhevet produkt'));

	if (!products?.length > 0) return null;

	return (
		<div
			data-cy="products-wrapper"
			style={{ width: '100%', position: 'relative' }}>
			<SwitchWrapper
				doanimate={props?.transitions}
				direction="up"
				delay={1}>
				<TabSwitch
					activeTab={activeTab}
					setActiveTab={setActiveTab}
					fullwidth={true}
					tabs={[
						{
							id: 'monthy',
							text: 'Månedlig',
							ariaLabel: 'Vis månedlig pris',
						},
						{
							id: 'yearly',
							text: 'Årlig (spar 20%)',
							ariaLabel: 'Vis årlig pris',
						},
					]}
				/>
			</SwitchWrapper>

			<GridWrapper perrow={props?.perrow} total={products?.length}>
				{products?.map((p, i) => (
					<CustomProduct
						key={i}
						index={i}
						data={p}
						priceWithDiscount={activeTab === 'yearly'}
						transitions={props?.transitions}
						perrow={props?.perrow}
						anyhighlighted={anyhighlighted ? 'true' : 'false'}
					/>
				))}
			</GridWrapper>
		</div>
	);
}
