import React from 'react';
import styled, { css } from 'styled-components';

import ProductWrapper from '../ProductWrapper';
import Points from '../Points';
import Button from 'components/forms/Button';

const Price = styled.div`
	font-size: 32px;
	line-height: 54px;
	font-weight: 600;
`;

const Monthly = styled.span`
	margin-right: 7.5px;
	${p =>
		p.$lineThrough &&
		css`
			text-decoration: line-through;
			opacity: 0.3;
		`}
`;

const WithDiscount = styled.span`
	margin-right: 7.5px;
`;

const Suffix = styled.span`
	font-size: 17px;
	line-height: 46px;
	font-weight: 500;
`;

const Selectors = styled.div`
	margin-top: 20px !important;
	width: 100%;
	text-align: center;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: flex-end;
	${p =>
		p.theme.media.large(css`
			margin-top: 25px !important;
		`)}
	${p =>
		p.perrow === 1 &&
		css`
			${p =>
				p.theme.media.medium(css`
					flex-direction: row;
					justify-content: flex-start;
					align-items: center;
				`)}
		`}
`;

/**
 * A component for displaying product information and features.
 *
 * This component renders information about a product, including its title, subtitle,
 * price, features, and a choose button. It can also display a badge if the
 * product is marked as "Fremhevet produkt".
 *
 * @component
 * @param {Object} props - The props object for the Product component.
 * @param {Object} [props.data] - The data object containing product information.
 * @param {number} [props.index=0] - The index of the product within a list.
 * @param {boolean} [props.priceWithDiscount=true] - Determines whether to display price with discount.
 * @param {string} [props.transitions='false'] - Determines whether CSS transitions are enabled.
 * @param {integer} [props.perrow=3] - How many products per row
 * @param {string} [props.anyhighlighted='false'] - Determines whether any siblings are highlighted
 * @returns {JSX.Element|null} The rendered product component JSX element or null if data is missing.
 */
export default function Product({
	data = {},
	index = 0,
	priceWithDiscount = true,
	transitions = 'false',
	perrow = 3,
	anyhighlighted = 'false',
}) {
	if (!data) return null;

	return (
		<ProductWrapper
			doanimate={transitions}
			direction="up"
			perrow={perrow}
			anyhighlighted={anyhighlighted}
			delay={(index && 0.5 * index) || 0}
			highlighted={data?.settings
				?.includes('Fremhevet produkt')
				?.toString()}
			header={
				<>
					<h3>{data?.title}</h3>
					<p>{data?.subTitle}</p>
					<Price>
						<Monthly
							$lineThrough={
								priceWithDiscount && data?.monthlyPrice !== 0
							}>
							{data?.monthlyPrice}
						</Monthly>
						{priceWithDiscount && data?.monthlyPrice !== 0 && (
							<WithDiscount>
								{data?.monthlyPriceDiscount}
							</WithDiscount>
						)}
						<Suffix>kr/mnd</Suffix>
					</Price>
				</>
			}>
			<Points included={data?.features} />

			<Selectors perrow={perrow}>
				<Button
					title={data?.button?.text || `Velg ${data?.title}`}
					stretch={true}>
					{data?.button?.text || `Velg ${data?.title}`}
				</Button>
			</Selectors>
		</ProductWrapper>
	);
}
