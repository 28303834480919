import React from 'react';
import styled, { css } from 'styled-components';
import { navigate } from 'gatsby';

import { track } from 'context/AnalyticsProvider';
import Points from '../Points';
import ProductWrapper from '../ProductWrapper';
import Button from 'components/forms/Button';
import { getUrl } from 'libs/content';

//#region Styling
const Image = styled.div`
	margin-bottom: 20px;
	img {
		max-width: 100px;
		width: auto;
		height: 100px;
	}
`;

const ButtonsWrap = styled.div`
	margin-top: 20px !important;
	width: 100%;
	text-align: center;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: flex-end;
	${p =>
		p.theme.media.large(css`
			margin-top: 25px !important;
		`)}
	button {
		justify-content: center;
	}
	${p =>
		p.perrow === 1 &&
		css`
			${p =>
				p.theme.media.medium(css`
					flex-direction: row;
					justify-content: flex-start;
					align-items: center;
					button {
						min-width: 280px;
					}
				`)}
		`}
`;

export default function SolarProduct({ ...product }) {
	if (!product) return null;

	return (
		<ProductWrapper
			{...product}
			key={product?.id}
			perrow={product?.perrow}
			header={
				<>
					{product?.image && (
						<Image>
							<img
								src={product?.image?.file?.url}
								alt={'Illustrasjon av ' + product?.name}
							/>
						</Image>
					)}
					<h3>{product.name}</h3>
					<p>{product.intro}</p>
				</>
			}>
			<Points included={product?.points} />

			<ButtonsWrap perrow={product?.perrow}>
				<Button
					title={`Be om tilbud på ${product?.name}`}
					stretch={true}
					onClick={() => {
						track('Button Clicked', {
							label: `Be om tilbud på ${product?.name}`,
							category: window.location.pathname,
						});

						navigate(
							product?.orderFormPage?.slug
								? getUrl(product?.orderFormPage?.slug)
								: '/solceller/bestill'
						);
					}}>
					Be om tilbud
				</Button>
			</ButtonsWrap>
		</ProductWrapper>
	);
}
