import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark';

import ScreenReaderText from 'components/screen-reader-text';
import ToolTip from 'components/ToolTip';
import { removeSpecialCharacters } from 'libs/content';

const List = styled.ul`
	list-style: none;
	margin: 0;
	padding-left: 35px;
	text-align: left;
	li {
		position: relative;
		margin-bottom: 10px;
		> svg {
			position: absolute;
			width: 20px;
			height: 25px;
			left: -35px;
			top: 2px;
			color: ${p => p.theme.colors.blue600};
		}
		&.check {
			> svg {
				color: ${p => p.theme.colors.green600};
			}
		}
		&.xmark {
			> svg {
				color: ${p => p.theme.colors.coral600};
			}
		}
	}
`;

export default function Points({
	included = [],
	notIncluded = [],
	style,
	className,
}) {
	if (!included.length > 0 && !notIncluded?.length > 0) return <></>;

	const features = generateFeaturesList({ included, notIncluded });

	if (!features.length > 0) return <></>;

	return (
		<List style={style} className={className}>
			{features.map((point, i) => {
				if (!point?.title) return null;
				return (
					<li
						key={i}
						className={(point?.included && 'check') || 'xmark'}>
						{point.included ? (
							<FontAwesomeIcon
								icon={faCheck}
								size="sm"
								style={{
									width: '19',
									height: '22',
								}}
							/>
						) : (
							<>
								<FontAwesomeIcon
									icon={faXmark}
									size="sm"
									style={{
										width: '19',
										height: '22',
									}}
								/>
								<ScreenReaderText>{`${point.title} ikke inkludert`}</ScreenReaderText>
							</>
						)}

						<span>{point.title}</span>
						{point?.text && (
							<ToolTip
								id={removeSpecialCharacters(point?.title)}
								position="top"
								aria-label={`Hold musepekeren over for å se informasjon om ${point?.title}`}>
								{point?.text}
							</ToolTip>
						)}
					</li>
				);
			})}
		</List>
	);
}

/**
 * Generates a features array based on included and not included points.
 *
 * @param {Object} props - The properties passed to the function.
 * @param {Array} props.points - An array of points that are included.
 * @param {Array} props.notIncluded - An array of points that are not included.
 *
 * @returns {Array} An array of feature objects. Each object has a 'title' property and an 'included' property.
 */
function generateFeaturesList({ included = [], notIncluded = [] } = {}) {
	const inc =
		(included?.length > 0 &&
			included
				?.map(p => (p && { title: p, included: true }) || null)
				.filter(Boolean)) ||
		[];
	const exc =
		(notIncluded?.length > 0 &&
			notIncluded
				?.map(p => (p && { title: p, included: false }) || null)
				.filter(Boolean)) ||
		[];

	return [...inc, ...exc];
}
